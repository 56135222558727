<template>
    <div id="profile-page">
        <toolbar-back-button-component :title="$t('profile_page.manage_account')"/>

        <div class="pa-4">
            <p class="font-weight-bold">{{ $t('profile_page.personal_information') }}</p>

            <div id="last-name" class="mb-2">
                <div class="white-text-field">
                    <v-text-field v-model="user.last_name" :label="$t('profile_page.last_name')" color="secondary"
                                  filled hide-details readonly
                                  @click="openDialogEdition($t('profile_page.modify_last_name'), $t('profile_page.last_name'), user.last_name, 'last_name')"
                                  data-cy="last-name-input"
                    >
                        <template v-slot:append>
                            <v-icon
                                @click="openDialogEdition($t('profile_page.modify_last_name'), $t('profile_page.last_name'), user.last_name, 'last_name')"
                            >
                                mdi-chevron-right
                            </v-icon>
                        </template>
                    </v-text-field>
                </div>
            </div>

            <div id="first-name" class="mb-2">
                <div class="white-text-field">
                    <v-text-field v-model="user.first_name" :label="$t('profile_page.first_name')" color="secondary"
                                  filled hide-details readonly
                                  @click="openDialogEdition($t('profile_page.modify_first_name'), $t('profile_page.first_name'), user.first_name, 'first_name')"
                                  data-cy="first-name-input"
                    >
                        <template v-slot:append>
                            <v-icon
                                @click="openDialogEdition($t('profile_page.modify_first_name'), $t('profile_page.first_name'), user.first_name, 'first_name')"
                            >
                                mdi-chevron-right
                            </v-icon>
                        </template>
                    </v-text-field>
                </div>
            </div>

            <div id="email" class="mb-2">
                <div class="white-text-field">
                    <v-text-field v-model="user.email" :label="$t('profile_page.email')" color="secondary"
                                  filled hide-details readonly
                                  @click="openDialogEdition($t('profile_page.modify_email'), $t('profile_page.email'), user.email, 'email')"
                                  data-cy="email-input"
                    >
                        <template v-slot:append>
                            <v-icon
                                @click="openDialogEdition($t('profile_page.modify_email'), $t('profile_page.email'), user.email, 'email')"
                            >
                                mdi-chevron-right
                            </v-icon>
                        </template>
                    </v-text-field>
                </div>
            </div>

            <v-dialog v-model="dialog_edition" max-width="500px">
                <v-card data-cy="dialog-edition">
                    <v-card-title>
                        <p class="font-weight-bold font-18 text-uppercase">{{ title_dialog_edition }}</p>
                    </v-card-title>

                    <v-form ref="form_profile">
                        <v-card-text class="pb-0">
                            <v-text-field v-model="property_value_to_edit" :label="label_input_edition"
                                          :rules="profile_rules" color="secondary" filled
                                          @keypress.enter="updateProfileUser()"
                                          data-cy="edit-profile-input"
                            />
                        </v-card-text>
                        <v-card-actions class="justify-end pb-4">
                            <v-btn text @click="closeDialogEditionProfile()" data-cy="cancel-btn">
                                <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                            </v-btn>
                            <v-btn :loading="loading_edit" @click="updateProfileUser()"
                                   class="btn-secondary-loader" color="secondary" text
                                   data-cy="submit-btn"
                            >
                                <span class="secondary--text font-weight-bold">{{ $t('global.validate') }}</span>
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>

            <div id="password" class="mb-2">
                <div class="white-text-field">
                    <v-text-field color="secondary" filled hide-details readonly type="password" value="password1234"
                                  @click="dialog_edition_password = true"
                                  data-cy="password-input"
                    >
                        <template v-slot:append>
                            <v-icon @click="dialog_edition_password = true">
                                mdi-chevron-right
                            </v-icon>
                        </template>
                    </v-text-field>
                </div>
            </div>

            <v-dialog v-model="dialog_edition_password" max-width="500px">
                <v-card data-cy="dialog-edition-password">
                    <v-card-title>
                        <p class="font-weight-bold font-18 text-uppercase">{{ $t('profile_page.modify_password') }}</p>
                    </v-card-title>

                    <v-form ref="form_password">
                        <v-card-text class="pb-0 pt-0">
                            <v-text-field v-model="old_password"
                                          :append-icon="show_old_password ? 'mdi-eye' : 'mdi-eye-off'"
                                          :label="$t('profile_page.actual_password')"
                                          :rules="[l => !!l || $t('profile_page.actual_password_required')]"
                                          :type="show_old_password ? 'text' : 'password'"
                                          color="secondary" filled
                                          @click:append="show_old_password = !show_old_password"
                            />

                            <v-text-field v-model="new_password"
                                          :append-icon="show_new_password ? 'mdi-eye' : 'mdi-eye-off'"
                                          :label="$t('profile_page.new_password')" :rules="new_password_rules"
                                          :type="show_new_password ? 'text' : 'password'"
                                          color="secondary" filled
                                          @click:append="show_new_password = !show_new_password"
                            />

                            <v-text-field v-model="new_password_confirm"
                                          :append-icon="show_new_password_confirm ? 'mdi-eye' : 'mdi-eye-off'"
                                          :label="$t('profile_page.confirm_new_password')"
                                          :rules="new_password_confirm_rules"
                                          :type="show_new_password_confirm ? 'text' : 'password'"
                                          color="secondary" filled
                                          @click:append="show_new_password_confirm = !show_new_password_confirm"
                                          @keypress.enter="updatePasswordUser()"
                            />
                        </v-card-text>

                        <v-card-actions class="justify-end pb-4">
                            <v-btn color="accent" text @click="closeDialogEditionPassword()" data-cy="password-cancel-btn">
                                <span class="font-weight-bold font-color-light">{{ $t('global.cancel') }}</span>
                            </v-btn>
                            <v-btn :loading="loading_edit" @click="updatePasswordUser()"
                                   class="font-weight-bold btn-secondary-loader" color="secondary" text
                            >
                                <span class="secondary--text font-weight-bold">{{ $t('global.validate') }}</span>
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>

            <div id="language" class="mb-2 mt-4">
                <v-text-field v-model="selected_language.name" @click="language_sheet = true"
                              label="Langue" readonly color="secondary" hide-details
                >
                    <template v-slot:append>
                        <v-icon @click="language_sheet = true">mdi-chevron-right</v-icon>
                    </template>
                </v-text-field>

                <v-bottom-sheet v-model="language_sheet">
                    <v-sheet v-touch:swipe.bottom="swipeBottomHandler" class="pa-4">
                        <p class="font-weight-bold font-18 text-uppercase">{{ $t('profile_page.language_choice') }}</p>
                        <v-radio-group v-model="selected_language" mandatory @change="editLanguage">
                            <v-radio v-for="language in languages" :key="language.id"
                                     :label="language.name" :value="language" class="mt-0" color="secondary"
                            />
                        </v-radio-group>
                    </v-sheet>
                </v-bottom-sheet>
            </div>

<!--            <v-divider class="mb-7 mt-7" color="white"/>

            <div id="preference">
                <p class="font-weight-bold mb-n3">{{ $t('profile_page.contact_preferences') }}</p>
                <v-list-item class="pl-0 pr-0">
                    <v-list-item-content class="font-14">
                        {{ $t('profile_page.manage_contact_preferences') }}
                    </v-list-item-content>
                    <v-list-item-icon>
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </div>-->

            <v-divider class="mb-7 mt-3" color="white"/>
            <v-btn block class="font-weight-bold mt-2" color="red" outlined small @click="dialog_remove_access = true">
                <span class="error--text">{{ $t('profile_page.delete_my_access_space') }}</span>
            </v-btn>
        </div>

        <v-dialog v-model="dialog_remove_access" max-width="500px">
            <v-card>
                <v-container>
                    <v-card-text class="text-center pb-1">
                        <p class="text-uppercase font-18 font-weight-bold red--text mb-2">
                            {{ $t('profile_page.delete_my_access_space') }}
                        </p>
                        <v-icon class="mx-auto" color="error" x-large>mdi-account-remove</v-icon>
                    </v-card-text>

                    <v-card-text class="font-weight-medium pb-0 pt-1">
                        <p class="mb-1">
                            {{
                                $t('profile_page.after_deletion_no_ore_access', {professional_name: $store.getters['user_connected/user_connected'].professional_name})
                            }}
                        </p>
                        <p>{{ $t('profile_page.access_remove_space') }}</p>
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn text @click="dialog_remove_access = false">
                            <span class="font-weight-bold">{{ $t('global.cancel') }}</span>
                        </v-btn>

                        <v-btn :loading="loading_edit" @click="removeAccess()"
                               class="font-weight-bold btn-error-loader" color="error" text
                        >
                            <span class="error--text">{{ $t('global.delete') }}</span>
                        </v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import UserRepository from "@/repositories/UserRepository";
import ToolbarBackButtonComponent from "@/components/Global/ToolbarBackButtonComponent";

export default {
    name: 'ProfilePage',
    components: {ToolbarBackButtonComponent},
    data() {
        return {
            user: this.$store.getters['user_connected/user_connected'],

            dialog_edition: false,
            loading_edit: false,
            property_value_to_edit: null,
            property_name: null,
            title_dialog_edition: '',
            label_input_edition: '',

            email_structure: /^\S+@\S+\.\S+$/,
            profile_rules: [
                l => !!l || this.$t('profile_page.field_required'),
                l => this.property_name === 'email' ? this.email_structure.test(l) || this.$t('profile_page.email_not_valid') : true
            ],

            dialog_edition_password: false,
            old_password: '',
            show_old_password: false,
            password_structure: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            new_password: '',
            show_new_password: false,
            new_password_rules: [
                v => !!v || this.$t('profile_page.new_password_required'),
                v => this.password_structure.test(v) || this.$t('profile_page.new_password_rule_creation'),
            ],
            new_password_confirm: '',
            show_new_password_confirm: false,
            new_password_confirm_rules: [
                v => !!v || this.$t('profile_page.confirm_new_password_required'),
                v => this.new_password === v || this.$t('profile_page.password_do_not_match'),
            ],

            language_sheet: false,
            selected_language: {
                id: 1,
                name: this.$t('profile_page.by_default')
            },
            languages: [
                {
                    id: 1,
                    name: this.$t('profile_page.by_default')
                },
                {
                    id: 2,
                    name: 'Français'
                },
                {
                    id: 3,
                    name: 'English'
                }
            ],
            dialog_remove_access: false
        }
    },
    methods: {
        openDialogEdition(titleDialogEdition, labelInputEdition, propertyValueToEdit, propertyName) {
            this.title_dialog_edition = titleDialogEdition;
            this.label_input_edition = labelInputEdition;
            this.property_value_to_edit = propertyValueToEdit;
            this.property_name = propertyName;
            this.dialog_edition = true;
        },

        updateProfileUser() {
            if (this.$refs.form_profile.validate()) {
                this.loading_edit = true;
                let oldPropertyValue = this.user[this.property_name];
                this.user[this.property_name] = this.property_value_to_edit;

                UserRepository.updateUserProfile(
                    this.user
                ).then((success) => {
                    this.$store.dispatch('user_connected/setSomePropsAuthenticateUser', success.data.data);
                    this.showSnackbar('success', this.$t('profile_page.success_profile_updated'));
                }).catch((error) => {
                    this.user[this.property_name] = oldPropertyValue;
                    this.manageError(error);
                }).finally(() => {
                    this.closeDialogEditionProfile();
                    this.loading_edit = false;
                });
            }
        },

        closeDialogEditionProfile() {
            this.title_dialog_edition = ''
            this.label_input_edition = '';
            this.property_value_to_edit = '';
            this.property_name = '';
            this.dialog_edition = false;
            this.$refs.form_profile.reset();
        },

        updatePasswordUser() {
            if (this.$refs.form_password.validate()) {
                this.loading_edit = true;
                UserRepository.updateUserPassword(
                    this.old_password, this.new_password
                ).then(() => {
                    this.showSnackbar('success', this.$t('profile_page.success_password_updated'));
                    this.closeDialogEditionPassword();
                }).catch((error) => {
                    this.manageError(error);
                    this.loading_edit = false;
                });
            }
        },

        closeDialogEditionPassword() {
            this.password = '';
            this.new_password = '';
            this.new_password_confirm = '';
            this.dialog_edition_password = false;
            this.$refs.form_password.reset();
        },

        editLanguage() {
            this.language_sheet = false;
        },

        swipeBottomHandler() {
            this.language_sheet = false;
        },

        removeAccess() {
            this.loading_edit = true;
            UserRepository.deleteUserAccess(this.$store.getters['user_connected/user_connected'].id_client).then(() => {
                this.showSnackbar('success', this.$t('profile_page.access_remove_successfully'));
                this.logout();
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.loading_edit = false;
                this.dialog_remove_access = false;
            })
        }
    }
}
</script>